import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { AnswerChoices } from '@app/models/question/answerChoices.model';
import { environement } from '@environments/environment';
import { ApiResponse } from '@models/common/api-response.model';
import { PageableResponse } from '@models/common/pageable-response.model';
import { Media } from '@models/media/media.model';
import {
  ProjectedQuestionDetails,
  QuestionDetail,
} from '@models/question/question-detail.model';
import { Question } from '@models/question/question.model';
import { BaseService } from '@services/base.service';
import { MediaService } from '@services/media.service';
import { isModelArray } from '@utils/validations';
import { PageRequest, PageResponse } from 'pf-ui';
import { MessageService } from 'primeng/api';
import { catchError, EMPTY, Observable, tap, throwError } from 'rxjs';
import { QuestionCountByStatus } from '@models/question/questionStatus.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionService extends BaseService {
  signalListPaginated = signal<PageResponse<Question> | undefined>(undefined);
  signalCurrent = signal<QuestionDetail | null>(null);
  signalTrainingQuestions = signal<QuestionDetail[] | undefined>(undefined);
  signalImageLoaderByQuestion = signal<Record<string, boolean> | undefined>(
    undefined,
  );
  signalImageLoader = computed(() => this.isQuestionLoadingUrlMedia());

  private isQuestionLoadingUrlMedia(): boolean {
    const currentLoaderByQuestion = this.signalImageLoaderByQuestion();
    if (currentLoaderByQuestion === undefined) return true;
    return !Object.values(currentLoaderByQuestion).every(
      (isLoading: boolean) => !isLoading,
    );
  }

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
    protected mediaService: MediaService,
  ) {
    super(http, messageService);
  }

  listPaginated(event?: PageRequest): Observable<PageResponse<Question>> {
    this.mediaService.signalImageLoaderByMedia.set(undefined);
    return this.executeRequest(
      this.http.get<PageableResponse<Question>>(
        `${environement.BACKEND_URL}/question/list`,
        {
          params: this.getHttpParams({
            ...event,
            sort: event?.sort ?? 'description',
          }),
        },
      ),
    ).pipe(
      this.mapPageableResponseToPageResponse<Question>(event),
      tap((questions) => {
        this.signalListPaginated.set(questions);
        if (
          questions.result?.every(
            (question) => (question as Question).id !== undefined,
          )
        ) {
          const questionKeysToCheck: (keyof Question)[] = [
            'id',
            'publicId',
            'description',
            'createdDate',
          ];

          if (isModelArray<Question>(questions.result, questionKeysToCheck)) {
            this.mediaService.signalImageLoaderByMedia.set(
              this.computedLoaderByImageId(questions.result),
            );
          }
        }
      }),
    );
  }

  getQuestionCountByStatus(): Observable<QuestionCountByStatus[]> {
    return this.executeRequest(
      this.http.get<QuestionCountByStatus[]>(
        `${environement.BACKEND_URL}/question/by-status/count`,
      ),
    ).pipe();
  }

  get(id: string): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.get<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${id}`,
      ),
    ).pipe(
      tap((val) => {
        this.signalCurrent.set(val);
      }),
    );
  }

  getAllTrainingQuestions(): Observable<ApiResponse<ProjectedQuestionDetails>> {
    this.signalImageLoaderByQuestion.set(undefined);
    return this.executeRequest(
      this.http.get<ApiResponse<ProjectedQuestionDetails>>(
        `${environement.BACKEND_URL}/question/search/findAllByCorrectionIsNotNull?projection=trainingQuestionList`,
      ),
    ).pipe(
      tap((questionDetails) => {
        const questionsDetails = questionDetails._embedded;
        const filteredQuestionDetails = questionsDetails?.question?.filter(
          (qd) => qd.correction,
        );
        if (filteredQuestionDetails) {
          this.signalTrainingQuestions.set(filteredQuestionDetails);

          this.signalImageLoaderByQuestion.set(
            this.computedLoaderByImageId(filteredQuestionDetails),
          );
        }
      }),
    );
  }

  post(body: Question): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.post<QuestionDetail>(`${environement.BACKEND_URL}/question`, {
        ...body,
        id: undefined,
      }),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  put(body: Question | QuestionDetail): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${body.id}/edit`,
        body,
      ),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  updateTrainingQuestion(
    body: Question | QuestionDetail,
  ): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${body.id}/edit/training-question`,
        body,
      ),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  suspend(question: QuestionDetail): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${question.id}/suspend`,
        question,
      ),
    );
  }

  reject(question: QuestionDetail): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${question.id}/reject`,
        question,
      ),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  validate(question: QuestionDetail): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${question.id}/validate`,
        question,
      ),
    ).pipe(
      tap((val) => {
        this.signalCurrent.set(val);
      }),
    );
  }

  delete(id?: string): Observable<object> {
    if (id === undefined) return EMPTY;

    return this.executeRequest(
      this.http.delete(`${environement.BACKEND_URL}/question/${id}`),
    );
  }

  generateSound(id: string): Observable<string> {
    return this.executeRequest(
      this.http.get<string>(
        `${environement.BACKEND_URL}/question/regenerate-sound/${id}`,
      ),
    ).pipe(
      catchError((err) => {
        return throwError(() => err);
      }),
    );
  }

  getRandomChoicesByAnswers(
    answerChoices: AnswerChoices[],
    answersNumber: number,
  ): Observable<AnswerChoices[]> {
    return this.executeRequest(
      this.http.post<AnswerChoices[]>(
        `${environement.BACKEND_URL}/question/random-choices/${answersNumber}`,
        answerChoices,
      ),
    );
  }

  getTrainingQuestions(categoryId?: string): Observable<QuestionDetail[]> {
    return this.executeRequest(
      this.http.post<QuestionDetail[]>(
        `${environement.BACKEND_URL}/question/training-questions`,
        { categoryId },
      ),
    ).pipe(
      tap((trainingQuestions) => {
        this.signalTrainingQuestions.set(trainingQuestions);
      }),
    );
  }

  setFlagged(question: Question): Observable<string> {
    return this.executeRequest(
      this.http.patch<string>(
        `${environement.BACKEND_URL}/question/flag`,
        question,
      ),
    ).pipe();
  }

  setUnFlagged(question: Question): Observable<string> {
    return this.executeRequest(
      this.http.patch<string>(
        `${environement.BACKEND_URL}/question/unFlag`,
        question,
      ),
    ).pipe();
  }

  public computedLoaderByImageId(
    questions: Question[] | QuestionDetail[],
  ): Record<string, boolean> | undefined {
    if (questions === undefined) {
      return undefined;
    }
    return questions.reduce((accu, current) => {
      const currentQuestion = current as Question;
      const picture = currentQuestion?.picture as Media;
      if (!picture) return accu;
      accu = { ...accu, [picture.keyName ?? '']: true };
      return accu;
    }, {});
  }
}
